import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Layout from "../components/layouts/layout";
import Image from "../components/widgets/basico/image";
import { Link } from "gatsby";

export default function ServiciosTaller (props){

  const [ controlTitulo, setControlTitulo ] = useState("");
  const [ controlURL, setControlURL ] = useState({
    chevrolet: "",
    nissan: "",
    buick: "",
    cadillac: "",
    gmc: ""
  });
  const [ controlPage, setControlPage ] = useState(false);

  var locationURL = props.location.pathname;

  useEffect(() => {
    if(locationURL === '/servicios-de-taller/'){
      console.log("Estamos en la seccion de taller de servicio")
      setControlTitulo("Taller de Servicio");
      setControlURL({
        ...controlURL,
        chevrolet: "/chevrolet/taller-de-servicio",
        nissan: "/nissan/taller-de-servicio",
        buick: "/taller-de-servicio",
        cadillac: "/taller-de-servicio",
        gmc: "/taller-de-servicio"
      })
    } else if(locationURL === '/servicios-colision/'){
      console.log("Estamos en la seccion de taller de colision")
      setControlTitulo("Taller de Colisión");
      setControlURL({
        ...controlURL,
        chevrolet: "/chevrolet/taller-de-colision",
        nissan: "/nissan/taller-de-colision",
        buick: "/taller-de-colision",
        cadillac: "/taller-de-colision",
        gmc: "/taller-de-colision"
      })
    } else if(locationURL === '/servicios-refacciones/'){
      console.log("Estamos en la seccion de refacciones");
      setControlTitulo("Refacciones");
      setControlURL({
        ...controlURL,
        chevrolet: "/chevrolet/refacciones",
        nissan: null,
        buick: "/refacciones",
        cadillac: "/refacciones",
        gmc: "/refacciones"
      })
    } else if(locationURL === '/sucursal-seminuevos'){
      console.log("Estamos en la seccion de seminuevos");
      setControlPage(true);
      setControlURL({
        ...controlURL,
        chevrolet: "https://chevroletrivero.com/catalogo/seminuevos/",
        nissan: "https://nissanrivero.com/catalogo/seminuevos/",
        buick: null,
        cadillac: null,
        gmc: null
      })
    }
  }, [])

  return(
    <Layout>
      {
        controlPage === false ? (
          <Container className="pt-2 mt-4 pb-5" style={{backgroundColor: 'white'}}>
            <Container style={{height: '100%'}}>
              <div>
                <center>
                  <h1 className="p-2" style={{ fontSize: 25 }}>{controlTitulo}</h1>
                </center>
              </div>
              
              <Row className="mt-4">
                <Col style={{display: 'flex', justifyContent: 'center'}}>
                    <Link to={controlURL.chevrolet}>
                      <Image 
                        fileName="b_chevrolet" alt="Chevrolet"
                        style={{width: 280}}
                        imgStyle={{height:'100%'}}
                      />
                    </Link>
                </Col>
                {controlURL.nissan !== null ? (
                  <Col style={{display: 'flex', justifyContent: 'center'}}>
                      <Link to={controlURL.nissan}>
                        <Image 
                          fileName="b_nissan" alt="Nissan"
                          style={{width: 280}}
                          imgStyle={{height:'100%'}}
                        />
                      </Link>
                  </Col>
                ) : ""}
              </Row>
              <Row className="mt-4">
                {controlURL.buick !== null ? (
                  <Col style={{display: 'flex', justifyContent: 'center'}}>
                    <Link to={controlURL.buick} style={{backgroundColor:'#404040', borderRadius: 15, padding: 15, width: '70%', height: 70, display: 'flex', justifyContent: 'center'}}>
                      <Image 
                        fileName="b_buick" alt="Buick"
                        style={{height: '90%', width: '100%'}}
                        imgStyles={{objectFit: 'contain'}}
                      />
                    </Link>
                  </Col>
                ) : null}
                {controlURL.cadillac !== null ? (
                  <Col style={{display: 'flex', justifyContent: 'center'}}>
                    <Link to={controlURL.cadillac} style={{backgroundColor:'#404040', borderRadius: 15, padding: 15, width: '70%', height: 70, display: 'flex', justifyContent: 'center'}}>
                      <Image 
                        fileName="b_cadillac" alt="Cadillac"
                        style={{height: '90%', width: '100%'}}
                        imgStyles={{objectFit: 'contain'}}
                      />
                    </Link>
                  </Col>
                ) : null}
                {controlURL.gmc !== null ? (
                  <Col style={{display: 'flex', justifyContent: 'center'}}>
                    <Link to={controlURL.gmc} style={{backgroundColor:'#404040', borderRadius: 15, padding: 15, width: '70%', height: 70, display: 'flex', justifyContent: 'center'}}>
                      <Image 
                        fileName="b_gmc" alt="Gmc"
                        style={{height: '90%', width: '40%'}}
                        imgStyles={{objectFit: 'contain'}}
                      />
                    </Link>
                  </Col>
                ) : null}
              </Row>
            </Container>
          </Container>
        ) : 
        (
          <Container className="pt-4">
              <div>
                <center>
                  <Image 
                    fileName="seminuevos-estrena.png" 
                    alt="Estrena Seminuevos Con Seguridad"
                    imgStyles={{objectFit: 'contain'}}
                    style={{width: '40%', height: '40%'}}
                  />
                </center>
              </div>
              <Row className="text-white pt-4" style={{fontSize: 19}}>
                  Somos Distribuidores Autorizados expertos en analizar, examinar y evaluar de forma rigurosa cada vehículo para su certificación.
                  Nadie valora más tu Chevrolet o Nissan que nosotros. 
              </Row>
              <Row className="pt-4" style={{display: 'flex', justifyContent: 'center'}}>
                <Link to={controlURL.chevrolet} style={{width: '40%', display: 'flex', justifyContent: 'flex-end'}}>
                  <Image 
                    fileName="seminuevos-chevrolet.png" 
                    alt="Estrena Seminuevos Con Seguridad"
                    imgStyles={{objectFit: 'contain'}}
                    style={{width: '100%', height: '100%'}}
                  />
                </Link>
                <Link to={controlURL.nissan} style={{width: '40%'}}>
                  <Image 
                    fileName="seminuevos-nissan.png" 
                    alt="Estrena Seminuevos Con Seguridad"
                    imgStyles={{objectFit: 'contain'}}
                    style={{width: '100%', height: '100%'}}
                  />
                </Link>
              </Row>
              <Row className="text-white pt-4" style={{fontSize: 19}}>
                  Además contamos con un GRAN inventario de otras marcas del mercado.
              </Row>
              <Row  className="text-white pt-4" style={{fontSize: 12}}>
              *Otras marcas no entran al programa de Chevrolet Seminuevos Certificados y Nissan Seminuevos Garantizados.
              **No todos los autos de marca Chevrolet y Nissan entran al programa de Chevrolet Seminuevos Certificados y Nissan Seminuevos Garantizados.
              </Row>
          </Container>
        )
      }
    </Layout>
  );

}